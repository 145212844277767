import React, { createContext, useState } from 'react'
import getAxios from '../axios/Axios'
import request from '../request/request'
import { useDispatch } from 'react-redux'
const UserContext = createContext({
    userData: [],
    whoamiFunction: () => {
    },
    login: false
})
function UserProvider({ children }) {
    const [data, setData] = useState([])
    const [userLogin, setUserLogin] = useState(false)
    const dispatch = useDispatch()
    const whoamiFunction = async () => {
        try {
            const res = await getAxios(request.who_ami)
            if (res.data.apiresponse.type == "OK") {
                setData(res.data.record)
                dispatch({ type: "login", login: true })
                // window.location.href = "/#/dashboard"
            } else {
                dispatch({ type: "login", login: false })
                window.location.href = "/#/login"
            }
        } catch {
            setUserLogin(false)
        }
    }
    const UserContextValue = {
        userData: data,
        whoamiFunction: whoamiFunction,
        login: userLogin
    }
    return (
        <UserContext value={UserContextValue}>
            {children}
        </UserContext>
    )
}

export default UserProvider
export { UserContext }
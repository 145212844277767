const BaseUrl2 = process.env.REACT_APP_BASE_URL
const BaseUrlGPSLog = process.env.REACT_APP_BASE_URL_TRACKING_HOST

const request = {
    login_user: BaseUrl2 + "accounts/verify_user",
    who_ami: BaseUrl2 + "accounts/whoami",
    log_out: BaseUrl2 + "accounts/logout",
    getCourses: BaseUrl2 + "get_courses",
    updateCourse: BaseUrl2 + "update_course",
    getComments: BaseUrl2 + "get_comments",
    getStatus: BaseUrl2 + "get_course_statuses",
    registeredUser: BaseUrl2 + "last_location_status",
    nonRegisteredUser: BaseUrlGPSLog + "get_unmmaped_users",
    lastLocationStatus: BaseUrlGPSLog + "last_location_status",
    exportExcelFile: BaseUrlGPSLog + "export_lls",
    courseReport: BaseUrl2 + "course_completion_report",
    exportCourseFile: BaseUrl2 + "export_course_completion_report",
    export_user_report: BaseUrl2 + "export_user_report",
    userReport:BaseUrl2+"user_report",
    quizReport: BaseUrl2+"yancy/api/MdlQuiz",
    quizList: BaseUrl2+ "quiz_report",
    exportQuizReport: BaseUrl2+"export_quiz_report"
}
export default request
